import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import './http/http'
import { isMobile } from './utils/common'
Vue.prototype.$oss = 'https://oss.cuttlefish.vip/'
Vue.config.productionTip = false

document.title = '墨鱼旅行'

Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
