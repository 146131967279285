import Vue from "vue";
import VueRouter from "vue-router";
import { isMobile as getIsMobile } from "@/utils/common";

const isMobile = getIsMobile();
// 解决vue路由跳转连续点击报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

// PC
const index = () =>
    import(/* webpackChunkName: "index" */ "../views/pc/index.vue");
const Home = () =>
    import(/* webpackChunkName: "home" */ "../views/pc/Home.vue");
const CustomTour = () =>
    import(/* webpackChunkName: "customTour" */ "../views/pc/CustomTour.vue");
const AppDownload = () =>
    import(/* webpackChunkName: "appDownload" */ "../views/pc/AppDownload.vue");
const HotRoute = () =>
    import(/* webpackChunkName: "hotRoute" */ "../views/pc/HotRoute.vue");
const GoWith = () =>
    import(/* webpackChunkName: "goWith" */ "../views/pc/GoWith.vue");
const TravelCommunity = () =>
    import(
        /* webpackChunkName: "travelCommunity" */ "../views/pc/TravelCommunity.vue"
    );
const LatestNews = () =>
    import(/* webpackChunkName: "latestNews" */ "../views/pc/LatestNews.vue");

//  MOBILE
const mobileIndex = () =>
    import(/* webpackChunkName: "index" */ "../views/mobile/index.vue");
const mobileHome = () =>
    import(/* webpackChunkName: "home" */ "../views/mobile/Home.vue");
const mobileCustomTour = () =>
    import(
        /* webpackChunkName: "customTour" */ "../views/mobile/CustomTour.vue"
    );
const mobileAppDownload = () =>
    import(
        /* webpackChunkName: "appDownload" */ "../views/mobile/AppDownload.vue"
    );
const mobileHotRoute = () =>
    import(/* webpackChunkName: "hotRoute" */ "../views/mobile/HotRoute.vue");
const mobileGoWith = () =>
    import(/* webpackChunkName: "goWith" */ "../views/mobile/GoWith.vue");
const mobileTravelCommunity = () =>
    import(
        /* webpackChunkName: "travelCommunity" */ "../views/mobile/TravelCommunity.vue"
    );
const mobileLatestNews = () =>
    import(
        /* webpackChunkName: "latestNews" */ "../views/mobile/LatestNews.vue"
    );
Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: isMobile ? mobileIndex : index,
        redirect: "/home",
        children: [
            {
                // 首页
                path: "/home",
                name: "home",
                component: isMobile ? mobileHome : Home,
            },
            {
                // 定制游
                path: "/customTour",
                name: "customTour",
                component: isMobile ? mobileCustomTour : CustomTour,
            },
            {
                // 驴友社区
                path: "/travelCommunity",
                name: "travelCommunity",
                component: isMobile ? mobileTravelCommunity : TravelCommunity,
            },
            {
                // 结伴
                path: "/goWith",
                name: "goWith",
                component: isMobile ? mobileGoWith : GoWith,
            },
            {
                // 热门路线
                path: "/hotRoute",
                name: "hotRoute",
                component: isMobile ? mobileHotRoute : HotRoute,
            },
            {
                // APP下载
                path: "/appDownload",
                name: "appDownload",
                component: isMobile ? mobileAppDownload : AppDownload,
            },
            {
                // 最新资讯
                path: "/latestNews",
                name: "latestNews",
                component: isMobile ? mobileLatestNews : LatestNews,
            },
        ],
    },
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

export default router;
